//@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&display=swap');
// themes - our custom or/and out of the box themes
@import 'themes';
@import 'node_modules/@nebular/theme/styles/prebuilt/corporate';
@import '@nebular/auth/styles/globals';
@import "@nebular/theme/styles/globals";

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/grid';

@import "bootstrap/scss/bootstrap";
// loading progress bar theme
@import './pace.theme';
@import './layout';
@import './overrides';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import '@nebular/theme/styles/theming';
@import '@nebular/theme/styles/themes/default';
// install the framework and custom global styles
@include nb-install() {

  // framework global styles
  //@include nb-theme-global();
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();

  ::ng-deep .step-content {
    width: 100% !important;
  }
}


$font10: 0.625rem;
$font11: 0.688rem;
$font12: 0.75rem;
$font13: 0.813rem;
$font14: 0.875rem;
$font15: 0.938rem;
$font16: 1rem;
$font18: 1.125rem;
$font20: 1.25rem;
$font22: 1.375rem;
$font24: 1.5rem;
$font26: 1.625rem;
$font28: 1.75rem;
$font30: 1.875rem;
$font32: 2rem;
$font34: 2.125rem;
$font36: 2.25rem;
$font40: 2.5rem;
$font48: 3rem;
$medium: 500;
$bold: 600;
$bolder: 700;
$strong: 800;
$basecolor: var(--basecolor, #10C5B3);
$basecolor-h: var(--basecolor, #10C5B3);
$redcolor: #F53939;
$greencolor: #30c817;
$blackcolor: #333;
$font-size-base: 0.875rem !default;
$newfont: Inter;
$radius:.8rem;


div {transition:all .3s !important;}

.basecolor {color:var(--basecolor) !important;}

.basebgcolor {background:var(--basecolor) !important;}

.main-container-fixed {z-index: 1000;}

label.required::after {
  content: '*';
  color: red;
}

.cursor {
  cursor: pointer;
}
.h-100 {height: 100%}
.overflow-x-auto {overflow-x: auto;}
.overflow-y-auto {overflow-y: auto;}

.radius {border-radius: $radius;}

.black-color {color:$blackcolor;}

.nb-theme-corporate nb-sidebar .main-container {
  background: transparent;
}

.nb-theme-corporate nb-menu {
  background: transparent;
}

.nb-theme-corporate nb-menu .menu-item:nth-child(1) a nb-icon {
  color: #59a1ff !important;
}

.nb-theme-corporate nb-menu .menu-item:nth-child(2) a nb-icon {
  color: #7659ff !important;
}

.nb-theme-corporate nb-menu .menu-item:nth-child(3) a nb-icon {
  color: #ff2db5 !important;
}

.nb-theme-corporate nb-menu .menu-item:nth-child(4) a nb-icon {
  color: #00adc3 !important;
}

.nb-theme-corporate nb-menu .menu-item:nth-child(5) a nb-icon {
  color: #9cd318 !important;
}

.nb-theme-corporate nb-menu .menu-item:nth-child(6) a nb-icon {
  color: #7659ff !important;
}

.nb-theme-corporate nb-menu .menu-item:nth-child(7) a nb-icon {
  color: #f45b2c !important;
}

.nb-theme-corporate nb-menu .menu-item:nth-child(8) a nb-icon {
  color: #9c56c4 !important;
}

.nb-theme-corporate nb-menu .menu-item:nth-child(9) a nb-icon {
  color: #0F9B8E !important;
}
.nb-theme-corporate nb-menu .menu-item:nth-child(10) a nb-icon {
  color: #133374 !important;
}

.nb-theme-corporate nb-menu .menu-item:nth-child(11) a nb-icon {
  color: #C9B003!important;
}
.nb-theme-corporate nb-menu .menu-item:nth-child(12) a nb-icon {
  color: #4A0100 !important;
}
.nb-theme-corporate nb-menu .menu-item:nth-child(13) a nb-icon {
  color: #2DFE54 !important;
}

.nb-theme-corporate nb-menu .menu-item:nth-child(14) a nb-icon {
  color: #FD4659 !important;
}

.nb-theme-corporate nb-menu .menu-items > .menu-item {
  border-top: none;
  font-weight: bold;
  border-radius: 0;
  color: #505a64;
  border-bottom: none !important;
}

.nb-theme-corporate .menu-sidebar .scrollable {
  padding-top: 0 !important;
  background-color: #ffffff;
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3) !important
}

.menu-sidebar .menu-item a .menu-title {
  color: #fff;
}
.table-bordered td {
  border: 1px solid #cdd2d5;
}

.nb-theme-corporate nb-card {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1) !important;
  background-color: #ffffff;
  color: #505a64; border-radius:10px;
}

.nb-theme-corporate .header-container .navigation {
  font-size: 3.0rem;
}

.menu-sidebar.expanded .menu-item a.active {
  border-right: 8px solid #59a1ff;

}

.nb-theme-corporate nb-layout .layout nb-layout-header nav {
  //background: #f8f8fb;
  //background: #f8fbff;
  background: #ffffff;
  //background: #ebf5ff;
  color: #000;
}
.nb-theme-corporate nb-layout .layout {
  //background-color: #f8fbff;
  //background: #ebf5ff;
  //background: #eaeaea;
  background: #f1f5f8!important;
}

.marker-cluster-small {
  background-color: rgba(181, 226, 140, 0.6);
}

.marker-cluster-small div {
  background-color: rgba(152, 17, 206, 0.6);
}

.marker-cluster-medium {
  background-color: rgba(241, 211, 87, 0.6);
}

.marker-cluster-medium div {
  background-color: rgba(240, 194, 12, 0.6);
}

.marker-cluster-large {
  background-color: rgba(253, 156, 115, 0.6);
}

.marker-cluster-large div {
  background-color: rgba(241, 128, 23, 0.6);
}

/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small {
  background-color: rgb(181, 226, 140);
}

.leaflet-oldie .marker-cluster-small div {
  background-color: rgb(110, 204, 57);
}

.leaflet-oldie .marker-cluster-medium {
  background-color: rgb(241, 211, 87);
}

.leaflet-oldie .marker-cluster-medium div {
  background-color: rgb(240, 194, 12);
}

.leaflet-oldie .marker-cluster-large {
  background-color: rgb(253, 156, 115);
}

.leaflet-oldie .marker-cluster-large div {
  background-color: rgb(241, 128, 23);
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
}

.marker-cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;

  text-align: center;
  border-radius: 15px;
  font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
}


.nb-theme-corporate nb-menu .menu-item a {
  //color: #1a2138;
  border-radius: 0;
  color: #505a64;
  border-bottom: none !important;
}

.nb-theme-corporate nb-menu .menu-items > .menu-item{
  border-bottom: transparent;
}
.menu-sidebar .menu-item a .menu-title {
  color: #505a64;
}

.marker-cluster span {
  line-height: 30px;
  color: #000;
}

.notifications {
  left: -100% !important;
}

.nb-theme-corporate nb-user.shape-round .user-picture {
  background: #ffffff;
  border: solid 2px #bcc3cc;
}

.nb-theme-corporate nb-checkbox .custom-checkbox {
  border-color: #c0c0c0;
}

.nb-theme-corporate [nbInput] {
  // padding: 0.75rem 2rem;
}

.pac-container {
  z-index: 9999;
}

.nb-theme-corporate nb-stepper .header .label-index {
  width: 1.3rem;
  height: 1.3rem;
}

::ng-deep {
  .viewer-toolbar > ul > li::before {
    margin-top: -14px !important;
    margin-left: 2px !important;
  }
}

a:hover {
  color: #0056b3;
  text-decoration: none !important;
}

.mck-sidebox-launcher {
  bottom: 55px !important;
}

.nb-theme-corporate nb-select.size-giant:not(.full-width), .nb-theme-corporate .options-list:not(.full-width) {
  max-width: 100%;
}

.nb-theme-corporate [nbInput].size-medium:not(.input-full-width) {
  // max-width: inherit;
}

.angular-editor-toolbar {
  display: none;
}

::ng-deep {
  .icons-rupee{
    content: "\20B9";
    color: black !important;
  }
}

.ng-dropdown-panel.ng-select-bottom {
  z-index: 99999 !important;
}
.legend-wrap {
  min-width: fit-content !important;
}

.marker-pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #c30b82;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -15px 0 0 -15px;
}
// to draw white circle
.marker-pin::after {
  content: '';
  width: 24px;
  height: 24px;
  margin: 3px 0 0 3px;
  background: #fff;
  position: absolute;
  border-radius: 50%;
}

// to align icon
.custom-div-icon i {
  position: absolute;
  width: 22px;
  font-size: 14px;
  left: 0;
  right: 0;
  margin: 10px auto;
  text-align: center;
}


.c-logo img {height: 120px !important; padding:20px;}

.c-logo-inner img {min-height: 48px; max-height: 64px;}

.nb-theme-corporate nb-user.shape-round .user-picture {border-radius: 50%;}

.mbsc-windows.mbsc-textfield-wrapper {
  margin: 0 !important;
}

.mbsc-windows.mbsc-textfield-box, .mbsc-windows.mbsc-textfield-outline {
  font-weight: 400!important;
  font-size: .875rem!important;
  padding: 0 1rem!important;
  line-height: 48px!important;
  border-radius: 0.75rem!important;
  background-color: #e9edf1!important;
  border: 1px solid #e9edf1!important;
}

.mbsc-windows.mbsc-textfield {
  height: auto !important;
}

.nb-theme-corporate nb-sidebar .main-container {
  background: transparent;
}

.nb-theme-corporate nb-menu {
  background: transparent;
}

.nb-theme-corporate nb-menu .menu-item:nth-child(1) a nb-icon {
  color: #59a1ff !important;
}

.nb-theme-corporate nb-menu .menu-item:nth-child(2) a nb-icon {
  color: #7659ff !important;
}

.nb-theme-corporate nb-menu .menu-item:nth-child(3) a nb-icon {
  color: #ff2db5 !important;
}

.nb-theme-corporate nb-menu .menu-item:nth-child(4) a nb-icon {
  color: #00adc3 !important;
}

.nb-theme-corporate nb-menu .menu-item:nth-child(5) a nb-icon {
  color: #9cd318 !important;
}

.nb-theme-corporate nb-menu .menu-item:nth-child(6) a nb-icon {
  color: #7659ff !important;
}

.nb-theme-corporate nb-menu .menu-item:nth-child(7) a nb-icon {
  color: #f45b2c !important;
}

.nb-theme-corporate nb-menu .menu-item:nth-child(8) a nb-icon {
  color: #9c56c4 !important;
}

.nb-theme-corporate nb-menu .menu-item:nth-child(9) a nb-icon {
  color: #0F9B8E !important;
}
.nb-theme-corporate nb-menu .menu-item:nth-child(10) a nb-icon {
  color: #133374 !important;
}

.nb-theme-corporate nb-menu .menu-item:nth-child(11) a nb-icon {
  color: #C9B003!important;
}
.nb-theme-corporate nb-menu .menu-item:nth-child(12) a nb-icon {
  color: #4A0100 !important;
}
.nb-theme-corporate nb-menu .menu-item:nth-child(13) a nb-icon {
  color: #2DFE54 !important;
}

.nb-theme-corporate nb-menu .menu-item:nth-child(14) a nb-icon {
  color: #FD4659 !important;
}

.nb-theme-corporate nb-menu .menu-items > .menu-item {
  border-top: none;
  font-weight: bold;
  border-radius: 0;
  color: #505a64;
  border-bottom: none !important;
}

.nb-theme-corporate .menu-sidebar .scrollable {
  padding-top: 0 !important;
  background-color: #ffffff;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.15) !important
}

.table-bordered td {
  border: 1px solid #cdd2d5;
}

.nb-theme-corporate nb-toggle.status-basic .toggle {background-color: #e9edf1; border-color: #e9edf1;}
.nb-theme-corporate nb-toggle.status-basic .toggle:hover {background-color: var(--basecolor); border-color: var(--basecolor);}
.nb-theme-corporate nb-toggle.status-basic .toggle-switcher nb-icon {color: var(--basecolor);}
.nb-theme-corporate nb-toggle.status-basic .toggle.checked {background-color: var(--basecolor); border-color: var(--basecolor);}
.nb-theme-corporate nb-toggle.status-basic .native-input:enabled + .toggle:hover {background-color: var(--basecolor);	border-color: var(--basecolor);}
.nb-theme-corporate nb-toggle.status-basic .native-input:enabled + .toggle:hover.checked {background-color: var(--basecolor);	border-color: var(--basecolor);}
.nb-theme-corporate nb-toggle.status-basic .native-input:enabled:focus + .toggle {background-color: var(--basecolor);	border-color: var(--basecolor);}
.nb-theme-corporate nb-toggle.status-basic .native-input:enabled:focus + .toggle.checked {background-color: var(--basecolor);	border-color: var(--basecolor);}

.nb-theme-corporate nb-card {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0) !important;
  background-color: #ffffff;
  color: #333; border-radius:.8rem; border:0 solid #e4e9f2; margin-bottom: 0;
}

.nb-theme-corporate nb-card-footer {
  border-top: 1px solid rgba(0, 0, 0, .1); padding: 1rem 1rem;
}

.nb-theme-corporate .header-container .navigation {
  font-size: 3.0rem;
}

.menu-sidebar.expanded .menu-item a.active {
  border-left: 0 solid var(--basecolor) !important;
  border-right: 0;
}
.nb-theme-corporate nb-menu .menu-item a.active {
  background-color:none;
  border-color: var(--basecolor) !important;
}

.nb-theme-corporate nb-menu .menu-item .menu-icon {
  width: 1.7em;
}

.nb-theme-corporate nb-layout .layout nb-layout-header nav {
  //background: #f8f8fb;
  //background: #f8fbff;
  background: #ffffff;
  //background: #ebf5ff;
  color: #000;
}
.nb-theme-corporate nb-layout .layout {
  //background-color: #f8fbff;
  //background: #ebf5ff;
  //background: #eaeaea;
  background: #f1f5f8!important;
}

.nb-theme-corporate nb-layout .layout, .nb-theme-corporate nb-checkbox .text, .nb-theme-corporate .caption-2, .nb-theme-corporate h1, .nb-theme-corporate h2, .nb-theme-corporate h3, .nb-theme-corporate h4, .nb-theme-corporate h5, .nb-theme-corporate h6, .nb-theme-corporate .h1, .nb-theme-corporate .h2, .nb-theme-corporate .h3, .nb-theme-corporate .h4, .nb-theme-corporate .h5, .nb-theme-corporate .h6, body, .nb-theme-corporate nb-menu .menu-group, .nb-theme-corporate nb-menu .menu-item a, .nb-theme-corporate nb-user .user-name, .nb-theme-corporate nb-user .user-title, .nb-theme-corporate nb-card, .nb-theme-corporate, .nb-theme-corporate li, .nb-theme-corporate p, .nb-theme-corporate .paragraph, .nb-theme-corporate nb-card-header, .nb-theme-corporate nb-actions, .nb-theme-corporate nb-sidebar, .nb-theme-corporate nb-layout-header, .nb-theme-corporate nb-route-tabset .tab-link, .mbsc-font, .nb-theme-corporate nb-popover .primitive-overlay, nb-theme-corporate nb-select.appearance-outline.size-medium .select-button, .nb-theme-corporate nb-select .select-button, .nb-theme-corporate nb-option-list.size-medium nb-option, .nb-theme-corporate nb-list-item, .textarea, .nb-theme-corporate nb-tabset nb-tab, .nb-theme-corporate nb-radio .text, .nb-theme-corporate nb-tabset .tab-link, .nb-theme-corporate nb-user .initials, .nb-theme-corporate ng2-smart-table table tr th, .nb-theme-corporate ng2-smart-table table tr th a, .nb-theme-corporate ng2-smart-table table tr td, .nb-theme-corporate .label, .p-component, .p-inputtext, .nb-theme-corporate nb-toggle .text, .nb-theme-corporate nb-stepper .header .step {font-family: $newfont !important;}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  font-size: .875rem;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: var(--basecolor);
  color: #fff;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  font-size: .875rem;
  background-color: var(--basecolor);
  border-radius: 4px;
  margin-right: 5px; color: #fff;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  background-color: var(--basecolor);
  color: #fff;}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left:hover {
  background-color: $redcolor; border-radius: 4px 0 0 4px;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 15px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  padding-left: 6px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-right: 1px solid #fff;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  padding: 1px 6px;
}

.marker-cluster-small {
  background-color: rgba(181, 226, 140, 0.6);
}

.marker-cluster-small div {
  background-color: rgba(152, 17, 206, 0.6);
}

.marker-cluster-medium {
  background-color: rgba(241, 211, 87, 0.6);
}

.marker-cluster-medium div {
  background-color: rgba(240, 194, 12, 0.6);
}

.marker-cluster-large {
  background-color: rgba(253, 156, 115, 0.6);
}

.marker-cluster-large div {
  background-color: rgba(241, 128, 23, 0.6);
}

/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small {
  background-color: rgb(181, 226, 140);
}

.leaflet-oldie .marker-cluster-small div {
  background-color: rgb(110, 204, 57);
}

.leaflet-oldie .marker-cluster-medium {
  background-color: rgb(241, 211, 87);
}

.leaflet-oldie .marker-cluster-medium div {
  background-color: rgb(240, 194, 12);
}

.leaflet-oldie .marker-cluster-large {
  background-color: rgb(253, 156, 115);
}

.leaflet-oldie .marker-cluster-large div {
  background-color: rgb(241, 128, 23);
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
}

.marker-cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;

  text-align: center;
  border-radius: 15px;
  font: 12px "Inter", Arial, sans-serif;
}


.nb-theme-corporate nb-menu .menu-item a {
  //color: #1a2138;
  border-radius: 0;
  color: #505a64;
  border-bottom: none !important;
}

.nb-theme-corporate nb-menu .menu-items > .menu-item{
  border-bottom: transparent;
}

.marker-cluster span {
  line-height: 30px;
  color: #000;
}

.badge-note {border-radius: 50% !important;
  height: 16px !important;
  width: 16px !important;
  top: -1px !important;
  right: -1px !important;
  text-align: center !important;
  display: inline-block !important;
  font-size: 9px !important;
  padding: 0 !important;
  font-weight: 500 !important;
  line-height: 16px !important;}

.notifications {
  left: -100% !important;
}

.nb-theme-corporate nb-user.shape-round .user-picture {
  background: #ffffff;
  border: solid 2px #bcc3cc;
}

.nb-theme-corporate nb-checkbox .custom-checkbox {
  border-color: #c0c0c0;
}

.nb-theme-corporate [nbInput] {
  // padding: 0.75rem 2rem;
}

.pac-container {
  z-index: 9999;
}

.nb-theme-corporate nb-stepper .header .label-index {
  width: 1.3rem;
  height: 1.3rem;
}

::ng-deep {
  .viewer-toolbar > ul > li::before {
    margin-top: -14px !important;
    margin-left: 2px !important;
  }
}

a:hover {
  color: #0056b3;
  text-decoration: none !important;
}

.mck-sidebox-launcher {
  bottom: 55px !important;
}

.nb-theme-corporate nb-select.size-giant:not(.full-width), .nb-theme-corporate .options-list:not(.full-width) {
  max-width: 100%;
}

.nb-theme-corporate [nbInput].size-medium:not(.input-full-width) {
  // max-width: inherit;
}

.angular-editor-toolbar {
  display: none;
}

::ng-deep {
  .icons-rupee{
    content: "\20B9";
    color: black !important;
  }
}

.ng-dropdown-panel.ng-select-bottom {
  z-index: 99999 !important;
}
.legend-wrap {
  min-width: fit-content !important;
}

.marker-pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #c30b82;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -15px 0 0 -15px;
}
// to draw white circle
.marker-pin::after {
  content: '';
  width: 24px;
  height: 24px;
  margin: 3px 0 0 3px;
  background: #fff;
  position: absolute;
  border-radius: 50%;
}

// to align icon
.custom-div-icon i {
  position: absolute;
  width: 22px;
  font-size: 14px;
  left: 0;
  right: 0;
  margin: 10px auto;
  text-align: center;
}

::-webkit-scrollbar {width: 9px;}
::-webkit-scrollbar-track {background: #e3e9ee;}
::-webkit-scrollbar-thumb {background: #bcc8d2; border-radius: 8px;}
::-webkit-scrollbar-thumb:hover {background: #a7b3bc;}

.font10 {font-size:$font10 !important;}
.font11 {font-size:$font11 !important;}
.font12 {font-size:$font12 !important;}
.font13 {font-size:$font13 !important;}
.font14 {font-size:$font14 !important;}
.font15 {font-size:$font15 !important;}
.font16 {font-size:$font16 !important;}
.font18 {font-size:$font18 !important;}
.font20 {font-size:$font20 !important;}
.font22 {font-size:$font22 !important;}
.font24 {font-size:$font24 !important;}
.font26 {font-size:$font26 !important;}
.font28 {font-size:$font28 !important;}
.font30 {font-size:$font30 !important;}
.font32 {font-size:$font32 !important;}
.font34 {font-size:$font34 !important;}
.font36 {font-size:$font36 !important;}
.font40 {font-size:$font40 !important;}
.font48 {font-size:$font48 !important;}

.normal {font-weight: 400 !important;}
.medium {font-weight: $medium !important;}
.bold {font-weight: $bold !important;}
.bolder {font-weight: $bolder !important;}
.strong {font-weight: $strong !important;}

.color-grey {color:#999 !important;}
.color-base {color:var(--basecolor) !important;}
.color-red {color:$redcolor !important;}
.color-green {color:$greencolor !important;}
.color-white {color:$white;}
.color-txt1 {color:#5C90EF !important;}
.color-txt2 {color:#4DBFF0 !important;}
.color-txt3 {color:#A3DD4F !important;}
.color-txt4 {color:#7042DD !important;}
.color-txt5 {color:#EA602B !important;}
.color-txt6 {color:#EA602B !important;}
.color-txt7 {color:#41CB6B !important;}
.color-txt8 {color:#AABC3A !important;}
.color-txt9 {color:#333 !important;}

b, strong {
  font-weight: $bold !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}
.w-50 {
  width: 50% !important;
}
.w-60 {
  width: 60% !important;}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.vertical-top {vertical-align: top !important;}


.m-0 {margin:0 !important;}
.m-5 {margin:5px !important;}
.m-10 {margin:10px !important;}
.m-15 {margin:15px !important;}
.m-20 {margin:20px !important;}
.m-30 {margin:30px !important;}
.m-40 {margin:40px !important;}
.m-50 {margin:50px !important;}

.mtb-0 {margin-top:0 !important; margin-bottom:0 !important;}
.mlr-0 {margin-left:0 !important; margin-right:0 !important;}

.mt-0 {margin-top:0 !important;}
.mt-3 {margin-top:3px !important;}
.mt-5 {margin-top:5px !important;}
.mt-8 {margin-top:8px !important;}
.mt-10 {margin-top:10px !important;}
.mt-15 {margin-top:15px !important;}
.mt-16 {margin-top:16px !important;}
.mt-20 {margin-top:20px !important;}
.mt-30 {margin-top:30px !important;}
.mt-40 {margin-top:40px !important;}
.mt-50 {margin-top:50px !important;}

.mb-0 {margin-bottom:0 !important;}
.mb-3 {margin-bottom:3px !important;}
.mb-5 {margin-bottom:5px !important;}
.mb-10 {margin-bottom:10px !important;}
.mb-15 {margin-bottom:15px !important;}
.mb-20 {margin-bottom:20px !important;}
.mb-24 {margin-bottom:24px !important;}
.mb-25 {margin-bottom:25px !important;}
.mb-27 {margin-bottom:27px !important;}
.mb-30 {margin-bottom:30px !important;}
.mb-40 {margin-bottom:40px !important;}
.mb-50 {margin-bottom:50px !important;}
.mb-55 {margin-bottom:55px !important;}
.mb-60 {margin-bottom:60px !important;}

.ml-5 {margin-left:5px !important;}
.ml-6 {margin-left:6px !important;}
.ml-10 {margin-left:10px !important;}
.ml-15 {margin-left:15px !important;}
.ml-20 {margin-left:20px !important;}
.ml-30 {margin-left:30px !important;}
.ml-40 {margin-left:40px !important;}
.ml-50 {margin-left:50px !important;}

.mr-5 {margin-right:5px !important;}
.mr-10 {margin-right:10px !important;}
.mr-15 {margin-right:15px !important;}
.mr-20 {margin-right:20px !important;}
.mr-30 {margin-right:30px !important;}
.mr-40 {margin-right:40px !important;}
.mr-50 {margin-right:50px !important;}

.p-0 {padding:0 !important;}
.p-5 {padding:5px !important;}
.p-6 {padding:6px !important;}
.p-10 {padding:10px !important;}
.p-15 {padding:15px !important;}
.p-20 {padding:20px !important;}
.p-24 {padding:24px !important;}
.p-26 {padding:26px !important;}
.p-30 {padding:30px !important;}
.p-40 {padding:40px !important;}
.p-50 {padding:50px !important;}

.ptb-0 {padding-top:0 !important; padding-bottom:0 !important;}
.plr-0 {padding-left:0 !important; padding-right:0 !important;}

.pt-0 {padding-top:0 !important;}
.pt-3 {padding-top:3px !important;}
.pt-5 {padding-top:5px !important;}
.pt-6 {padding-top:6px !important;}
.pt-7 {padding-top:7px !important;}
.pt-10 {padding-top:10px !important;}
.pt-12 {padding-top:12px !important;}
.pt-15 {padding-top:15px !important;}
.pt-20 {padding-top:20px !important;}
.pt-25 {padding-top:25px !important;}
.pt-30 {padding-top:30px !important;}
.pt-40 {padding-top:40px !important;}
.pt-50 {padding-top:50px !important;}

.pb-0 {padding-bottom:0 !important;}
.pb-5 {padding-bottom:5px !important;}
.pb-10 {padding-bottom:10px !important;}
.pb-15 {padding-bottom:15px !important;}
.pb-20 {padding-bottom:20px !important;}
.pb-25 {padding-bottom:25px !important;}
.pb-30 {padding-bottom:30px !important;}
.pb-40 {padding-bottom:40px !important;}
.pb-50 {padding-bottom:50px !important;}

.pl-0 {padding-left:0 !important;}
.pl-4 {padding-left:4px !important;}
.pl-5 {padding-left:5px !important;}
.pl-10 {padding-left:10px !important;}
.pl-15 {padding-left:15px !important;}
.pl-20 {padding-left:20px !important;}
.pl-28 {padding-left:28px !important;}
.pl-30 {padding-left:30px !important;}
.pl-40 {padding-left:40px !important;}
.pl-50 {padding-left:50px !important;}

.pr-0 {padding-right:0 !important;}
.pr-5 {padding-right:5px !important;}
.pr-7 {padding-right:7px !important;}
.pr-10 {padding-right:10px !important;}
.pr-15 {padding-right:15px !important;}
.pr-20 {padding-right:20px !important;}
.pr-25 {padding-right:25px !important;}
.pr-30 {padding-right:30px !important;}
.pr-40 {padding-right:40px !important;}
.pr-50 {padding-right:50px !important;}

.pagepad {padding:20px 20px 19px 20px !important;}

::placeholder {font-weight:400 !important; font-size:$font14 !important; opacity: 1;}
:-ms-input-placeholder {font-weight:400 !important; font-size:$font14 !important;}
::-ms-input-placeholder {font-weight:400 !important; font-size:$font14 !important;}

.leftbgCol {background: var(--basecolor);}
.rightbgCol {background:#fff;}

.login-form {width:560px;}
.linehnormal {line-height: normal !important;}
.line-h12 {line-height: 1.2 !important;}
.line-h13 {line-height: 1.3 !important;}
.line-h14 {line-height: 1.4 !important;}
.line-h15 {line-height: 1.5 !important;}

label {
  font-weight: $medium !important; font-size:$font14 !important;
}

.nb-theme-corporate .caption {padding-top:5px; font-weight: 500;}

.nb-theme-corporate [nbInput] {font-weight:400 !important; font-size:$font14 !important; padding: 0 1rem !important; line-height:48px !important; border-radius: 0.75rem !important; background-color: #e9edf1 !important; border: 1px solid #e9edf1 !important; font-family: $newfont !important;}
.nb-theme-corporate [nbButton] {font-weight:600 !important; line-height:48px !important; border-radius: 0.75rem !important; padding: 0 .8rem !important; font-family: $newfont !important;background: var(--basecolor) !important; border: 0 !important; color: #fff !important;}
input:-webkit-autofill {-webkit-box-shadow: 0 0 0 1000px #e9edf1 inset !important; font-weight:400 !important; font-size:$font14 !important; font-family: $newfont !important;}


.custom-file {height:50px;}

.text-titlecase {text-transform: capitalize !important;}

.custom-file-input {
  height: 48px;
}

.custom-file-label {
  height: auto;
  padding: 0 0.75rem 0 0.95rem;
  line-height: 48px;
  background-color: #e9edf1 !important;
  border: 1px solid #e9edf1;
  border-radius: 0.75rem;
}

.custom-file-label::after {
  top: -1px; right:-1px;
  height: 50px;
  padding: 0 0.75rem;
  line-height: 50px;
  color: #fff;
  content: "BROWSE"; font-size: $font12;
  background-color: var(--basecolor); font-weight: $bold;
  border-left: inherit;
  border-radius: 0 0.75rem 0.75rem 0; min-width: 94px;
  text-align: center;
}

.smb {
  line-height: 43px !important; height: 44px;
  border-radius: 0.5rem !important;}

.ng-select.ng-select-single .ng-select-container .ng-value-container, .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  padding-left: 8px !important;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  left: 6px !important;
}

.viewbtn {
  a {text-decoration: none;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    background: #fff;
    margin-left: 12px;
    display: inline-block;
    line-height: 48px;
    border-radius: 0.8rem;
    padding: 0 16px;
    border: 1px solid var(--basecolor);
    color: var(--basecolor);}

  a:hover {
    background: var(--basecolor);
    color: #fff;}

  a:focus {
    background: #fff;
    color: var(--basecolor);}
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #e9edf1 !important;
}

.nb-theme-corporate [nbButton].appearance-filled.status-primary, .nb-theme-corporate .appearance-filled.status-primary[nbButtonToggle] {
  background-color: var(--basecolor);
  border-color: var(--basecolor); font-family: $newfont !important;
}

.nb-theme-corporate [nbButton].appearance-filled.status-primary:hover, .nb-theme-corporate .appearance-filled.status-primary:hover[nbButtonToggle] {
  background-color: $basecolor-h; opacity:.9;
  border-color: $basecolor-h;  font-family: $newfont !important;
}

.nb-theme-corporate [nbButton].appearance-outline.status-primary:focus, .nb-theme-corporate .appearance-outline.status-primary:focus[nbButtonToggle] {
  background-color: $basecolor-h; opacity:.9;
  border-color: $basecolor-h; color: #fff;
}

.valignT {vertical-align: top !important;}

.nb-theme-corporate .overlay-backdrop {
  background: rgba(0, 0, 0, 0.4); backdrop-filter: saturate(180%) blur(12px);
  -webkit-backdrop-filter: saturate(180%) blur(10px);
}

.nb-theme-corporate .overlay-backdrop::before {
  top: 15px; right:24px; z-index: 99999;
  position: absolute;
  color: #fff;
  content: "X";
  font-size: $font22;
  cursor: pointer;
}

/*nb-dialog-container {width: 90% !important; margin-left: 5% !important;}*/

.linkedModels {padding-right: 20px; padding-left: 10px;
  font-size: $font13;
  font-weight: $medium;
  max-width: 30ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 2.25rem;}

.nb-theme-corporate .caption-2 {color: var(--basecolor); border-bottom:1px solid var(--basecolor); text-decoration: none;}
.nb-theme-corporate .caption-2:hover {color: var(--basecolor); opacity: .8; border-bottom:1px solid var(--basecolor);}

.nb-theme-corporate nb-checkbox.status-basic .custom-checkbox.checked {
  background-color: var(--basecolor);
  border-color: #fff;
}

.nb-theme-corporate nb-checkbox.status-basic .native-input:enabled:focus + .custom-checkbox.indeterminate, .nb-theme-corporate nb-checkbox.status-basic .native-input:enabled:focus + .custom-checkbox.checked {
  background-color: var(--basecolor);
  border-color: var(--basecolor);
}

.nb-theme-corporate [nbButton].appearance-filled.status-primary:focus, .nb-theme-corporate .appearance-filled.status-primary:focus[nbButtonToggle] {
  background-color: var(--basecolor);
  border-color: var(--basecolor);
}

.nb-theme-corporate .nb-form-field-prefix-medium nb-icon, .nb-theme-corporate .nb-form-field-suffix-medium nb-icon {font-size: 1.5rem;}

.nb-theme-corporate .nb-form-field-prefix-medium, .nb-theme-corporate .nb-form-field-suffix-medium {
  width: 3.5rem;
}

.nb-theme-corporate nb-actions.size-medium nb-action {
  padding: 0 0 0 0;
}

.nb-theme-corporate nb-layout .layout .layout-container .content nb-layout-footer nav {
  padding: 0.8rem 1.25rem;
}

.nb-theme-corporate nb-user.shape-round .user-picture {
  border: 1px solid #bcc3cc;
}

.nb-theme-corporate nb-layout-header {
  box-shadow: 0 -5px 10px rgba(0,0,0,0.3) !important;
}

.nb-theme-corporate nb-sidebar.compacted {
  width: 4.5rem;
}
.nb-theme-corporate nb-sidebar.compacted nb-menu {
  width: 4.5rem;
}
.nb-theme-corporate nb-sidebar.compacted .main-container {
  width: 4.5rem;
}

[dir=ltr] .nb-theme-corporate .nb-form-field-suffix-medium {
  margin-left: calc(3.5rem * -1);
}

[dir=ltr] .info-container {
  margin-left: 0.6rem !important;
}

.nb-theme-corporate nb-user.size-medium .user-name {
  font-weight: 600; overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 18ch;
}

.nb-theme-corporate nb-user.size-medium .user-title {
  /*overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 22ch;*/ color: #999;
}

.nb-theme-corporate nb-user.size-medium .user-picture {
  height: 2.8rem;
  width: 2.8rem;
}


.ul-none{list-style-type: none; padding-left: 0; margin-bottom:0;}

.border-radius {border-radius: 0.8rem !important;}
.border-g {border-top:1px solid #eee;}
.d-cursor {cursor: pointer;}
.picsum-img-wrapper {
  width: 100%;
}

.version-m {position: relative;
  left: -20px;
  width: 72px;
  bottom: -30px;
  display: block;}

.ellip {overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 12ch;}


.nb-theme-corporate nb-context-menu {
  border-width: 0;
  border-radius: 0.8rem;
  min-width: 12rem;
  box-shadow: 0 3px 15px rgba(0,0,0,0.2) !important;
}

.nb-theme-corporate nb-menu .menu-item a:hover {color: var(--basecolor);}

.nb-theme-corporate nb-context-menu nb-menu {text-align: left; padding: 6px 16px 16px 16px;}

.nb-theme-corporate nb-menu .menu-item a.active {
  background-color: #f8f8f8; border-color: var(--basecolor) !important;
  box-shadow: inset 10px 0 0 0 var(--basecolor);
}

.nb-theme-corporate nb-sidebar.compacted nb-menu .menu-item a.active::before {
  background: var(--basecolor);
}

.nb-theme-corporate nb-menu .menu-group, .nb-theme-corporate nb-menu .menu-item a {
  padding: 0.85rem 1rem;
}

.nb-theme-corporate nb-menu .menu-item .menu-icon {height:auto;}

.menu-sidebar .menu-item a .menu-title {
  color: #333;
  font-weight: 500;
}

.nb-theme-corporate .nb-form-field-prefix-medium, .nb-theme-corporate .nb-form-field-suffix-medium {
  width: 3rem;
}

[dir=ltr] .nb-theme-corporate .nb-form-field-suffix-medium {
  margin-left: calc(3rem * -1);
}

[dir=ltr] .nb-theme-corporate .nb-form-field-control-with-suffix [nbInput].size-medium {
  padding-right: 2.5rem !important;
}

.nb-theme-corporate nb-card.size-medium {padding:16px; height: 36rem;}

.nb-theme-corporate nb-card-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: #333;
  font-size: $font18;
  font-weight: $bold;
  line-height: 1.5rem;
  background: #fff;
  border-radius: 0.8rem 0.8rem 0 0; display: flex; justify-content: space-between; align-items: center;
  padding: 0 1rem 0 1rem;
  min-height: 64px;
}

.nb-theme-corporate nb-card-body {
  padding: 1rem 1rem;

}

.nb-theme-corporate .notificationToggle {width:410px !important; background: #fff; border-radius: .8rem !important; height: 66vh !important; min-height: 40vh; right: 1vw !important;  top: 76px !important; box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2) !important;
  .nb-card {width: 100%; height: 100%; padding-bottom: 10px; background: #fff; border-radius:$radius  !important; box-shadow: none !important; border:0 !important;
    .nb-card-header {padding:0; width: 100%; background: none; border-bottom: 2px solid #ccc; margin-bottom: 0; display: block;
      h2 {font-size:$font20; font-weight: 600; padding: 12px 16px	0 20px;}
    }

    .nav-pills .nav-item {
      display: inline-block;
    }
    .nav-pills .nav-link {cursor: pointer; color: #707070; position: relative;
      border-radius: 0;
      font-size: 1rem;
      font-weight: 500;
      padding:0 21px; line-height: 42px; text-align: center;
    }

    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
      color:var(--basecolor); background: #fff;
    }
    .nav-pills .nav-link.active, .nav-pills .show > .nav-link::after {
      border-bottom:3px solid var(--basecolor);
    }

    .note-details {padding: 6px 0 16px 0 !important;
      overflow-y: auto;
      width: 100%;
      height: 100%;
      overflow-x: hidden;
      .note-itmes {background: #fff; padding: 16px; border-bottom: 1px solid #eee !important;}
    }
  }

}

//
//.nb-theme-corporate .notificationToggle:before {
//	content: " ";
//	position: absolute;
//	right: 10px;
//	top: -26px;
//	border-top: none;
//	border-right: 11px solid transparent;
//	border-left: 11px solid transparent;
//	border-bottom: 11px solid var(--basecolor);
//}

.close-btn {font-size: 16px;
  cursor: pointer;
  background: #fff;
  width: 42px;
  height: 42px;
  display: inline-block;
  position: absolute;
  right: 6px;
  top: 13px;
  border-radius: 50%;
  line-height: 42px;
  text-align: center;}

.ng-select .ng-select-container {
  border-radius: .75rem;
  min-height: 50px; background-color: #e9edf1;
  border: 1px solid #e9edf1;
}

.w-select .w-bg .ng-select-container {background-color: #fff !important;}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 13px;
  padding-bottom: 0;
  padding-left: 6px;
  font-size: $font14;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {color:#222b45; font-size: 0.875rem !important; padding-left: 6px;}

.opacity6 {opacity: .6;}

.nb-theme-corporate h1, .nb-theme-corporate .h1, .nb-theme-corporate h2, .nb-theme-corporate .h2, .nb-theme-corporate h3, .nb-theme-corporate .h3, .nb-theme-corporate h4, .nb-theme-corporate .h4, .nb-theme-corporate h5, .nb-theme-corporate .h5, .nb-theme-corporate h6, .nb-theme-corporate .h6 {font-weight: 600 !important;}

.nb-theme-corporate [nbButton].appearance-outline.status-primary, .nb-theme-corporate .appearance-outline.status-primary[nbButtonToggle] {
  background-color: #fff;
  border-color: var(--basecolor);
  color: var(--basecolor);
}

.nb-theme-corporate [nbButton].appearance-outline.status-primary:hover, .nb-theme-corporate .appearance-outline.status-primary:hover[nbButtonToggle] {
  background-color: var(--basecolor);
  border-color: var(--basecolor);
  color: #fff;
}

.nb-theme-corporate nb-checkbox .custom-checkbox {
  border-radius: 0.3rem;
}

.mb-user {display:none; background: var(--basecolor);
  width: calc(100% + 40px);
  margin: 0 0 10px -20px;
  padding: 16px 0 16px 0;
  border-radius: 0;
  position: relative;
  left: 0;
  top: 0;
  z-index: 2;

  .p-pic {width:48px; display: inline-block; margin-right: 10px; border-radius: 50%; border:1px solid #eee;}
  .company-name {margin-bottom: 0;
    span {display: block; color: #fff; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; max-width: 15ch;}
    span:last-child {max-width: 19ch;}
  }
  a {font-weight:500; background: #fff; border-radius: 1rem; display: inline-block; padding: 8px 24px; text-decoration: none; color: var(--basecolor); margin: 0 6px;}

  .buttons {text-align: center;}
}

.calendaricon  {
  input {background: url(/src/assets/images/icon-calendar.svg) no-repeat 95% center !important;
    background-size: 20px !important;}
}
.pageheader {border-bottom: 0 solid #9fb2c2; font-size: $font26 !important; font-weight: $bolder !important; margin-bottom: 10px;}

.route-tabset .route-tab {
  padding: 0 .5rem !important;
}
.nb-theme-corporate nb-route-tabset .tab-link {
  padding: 20px 1rem !important;
  font-size: $font13;
  font-weight: $medium;
  text-transform: capitalize !important; color: #79808D;
}

.nb-theme-corporate nb-route-tabset .route-tab:hover .tab-link {
  color: var(--basecolor);
}

.nb-theme-corporate nb-route-tabset .route-tab.active .tab-link {
  color: var(--basecolor);
}
.nb-theme-corporate nb-route-tabset .route-tabset {margin-bottom: 24px;
  border-bottom: 1px solid #eee; background: #fff; border-radius: 10px;
}

.nb-theme-corporate nb-route-tabset .route-tab:hover .tab-link::before {
  background-color: var(--basecolor);
}

.route-tabset .route-tab a::before {
  border-radius: 4px !important;
  bottom: 1px !important;
}

.nb-theme-corporate nb-route-tabset .tab-link::before {height:3px; bottom: 0;}

.nb-theme-corporate nb-route-tabset .route-tab.active .tab-link::before {
  background-color: var(--basecolor);
}

.lh-1 {line-height: 1 !important;}
.lh-12 {line-height: 1.1 !important;}
.lh-15 {line-height: 1.5 !important;}
.lh-2 {line-height: 2 !important;}
.lh-32 {line-height: 32px !important;}
.lh-36 {line-height: 36px !important;}
.w70px {width: 70px !important;}
.sminp {line-height: 36px !important; background: #fff !important;}
.no-bord {border:0 !important;}
.paging {margin-top: 20px;}

.table thead th {
  background: #e1e7ec;
  font-size: $font12 !important;
  font-weight: $bold !important;
  padding: 14px 10px !important;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 1);
}
.table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(0,0,0,.01);
}

.table-bordered th, .table-bordered td {
  border: 1px solid #c5cbd0 !important;
}
.table th, .table td {
  padding: 14px 10px !important;
  color: #212a32 !important; font-size: $font13 !important;
}

.table th {
  white-space: nowrap;
}

.nbgbor {background: none !important;; border:0 !important;}

.mbsc-ios.mbsc-textfield-box, .mbsc-ios.mbsc-textfield-outline {
  border-radius: 0.5rem !important;
}
.mbsc-ios.mbsc-textfield-wrapper-box, .mbsc-ios.mbsc-textfield-wrapper-outline {margin:0 !important;}

.mbsc-ios.mbsc-textfield {
  padding: 0 12px !important;
  height: 44px !important; font-size:$font14 !important;
}

.mbsc-ios.mbsc-textfield-box, .mbsc-ios.mbsc-textfield-outline {
  border-radius: 0.75rem !important; height: 50px !important;
}

.d-hotline {background-color: var(--basecolor) !important;}
.d-hotline.h-btn {box-shadow: none !important;}

.border-bottom {border-bottom: 1px solid #eee; padding-bottom: 10px;}

ngb-popover-window.bs-popover-bottom-right>.arrow, ngb-popover-window.bs-popover-top-right>.arrow {
  right: 0.5em !important;
}

.popover {border-radius: .5rem; border:0; box-shadow: 0 3px 15px rgba(0,0,0,.2); z-index: 99999 !important;}

.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  //border-width: 0 0.5rem 0.5rem 0.5rem;
  //border-bottom-color: rgba(0, 0, 0, 0.25);
  border-width: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.forms-section {/*background: #fff; border-radius: .8rem; padding: 16px;*/ margin-bottom: 24px; position: relative;}

.form-control {height:auto !important;}

.alert-danger {
  background-color: transparent;
  border-color: #f5c6cb;
  padding-top: 5px;
  font-weight: 500;
  color: #ff3d71;
  font-size: .75rem;
}

.alert {
  padding: 0;
  margin-bottom: 1rem;
  border: 0;
  border-radius: 0;
}

.info-ic {font-size: $font16; color:var(--basecolor); cursor: pointer;}
.nb-theme-corporate nb-popover .primitive-overlay {
  font-size: $font13;
  padding: 0.5rem 1rem;
}

.nb-theme-corporate nb-select.appearance-outline.size-medium .select-button {
  font-weight: 400 !important;
  font-size: 0.875rem !important;
  padding: 0 1rem !important;
  line-height: 48px !important;
  border-radius: 0.75rem !important;
  background-color: #e9edf1 !important;
  border: 1px solid #e9edf1 !important;
  color: #222b45 !important;}

.select-button nb-icon {font-size: 2em !important;}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {border-color: var(--basecolor) !important;}

.nb-theme-corporate nb-option-list {
  border-radius: 0.75rem; background: #fff;
}

.nb-theme-corporate nb-option.selected {
  background-color: var(--basecolor) !important;
}
.nb-theme-corporate nb-option-list.size-medium nb-option {
  font-size: $font14; font-weight: 400; line-height: 1.5rem;
  padding: 8px 10px; margin-bottom: 1px;
}
.nb-theme-corporate nb-option-list.size-medium nb-option:hover {background: var(--basecolor) !important; color:#fff !important;}

.nb-theme-corporate nb-option-list.size-medium nb-option.selected {color:#fff !important;}

.btn-w-12 {width:12rem !important;}

.mmenu-btn {position: absolute; padding: 0 10px; right: 0; top: 0; cursor: pointer;}
.mbtn {display:none;}

.ion-ios-search {font-size:$font22 !important;}

.popover-body {
  padding: 0;
}

.addnew-btn {background: #fff;
  color: var(--basecolor);
  font-size: $font11; text-transform: uppercase;
  padding: 8px 8px 8px 6px;
  line-height: 1;
  border-radius: 0.4rem;
  border: 1px solid var(--basecolor);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: $bolder;
  cursor: pointer; min-width: 96px;

  nb-icon {margin-right:4px;}
}

.addnew-btn:hover {background: var(--basecolor);
  color: #fff;
}

.namescont {height: 126px; overflow-y: auto !important; overflow-x: hidden !important;

  .info-container {display:block !important;}

  nb-list-item {padding: 0.4rem;
    border: 1px solid #bcc3cc !important;
    display: inline-block;
    border-radius: 100px; margin:0 .5rem .5rem 0;}

  nb-list-item:first-child {border: 1px solid #bcc3cc !important;}

  nb-user.size-medium .initials {font-size: $font12;}

  nb-user.shape-round .user-picture {border: 1px solid #ccc; height:2.5rem; width:2.5rem;}

  nb-user.size-medium .user-name {padding-right: 20px; font-size: $font13 !important; font-weight: $medium !important; max-width: 30ch !important;}

}

nb-list.namescont {display: block !important;}

.nb-theme-corporate nb-select.size-medium:not(.full-width), .nb-theme-corporate [nbInput].size-medium:not(.input-full-width)  {
  max-width: 100%;
}

.textarea {
  font-weight: 400 !important;
  font-size: $font14 !important;
  padding: 0.5rem 1rem !important;
  border-radius: 0.75rem !important;
  background-color: #e9edf1 !important;
  border: 1px solid #e9edf1 !important;
  line-height: 1.5 !important; outline: 0 !important; width: 100%;
}


.min-h30 {min-height: 20rem;}

.w-40 {width:40% !important;}

.bor-rgt0 {border-right:0 !important;}
.bor-lft0 {border-left:0 !important;}

.white_space {white-space: nowrap !important;}

input[type="date"]::-webkit-calendar-picker-indicator, input[type="time"]::-webkit-calendar-picker-indicator {
  cursor: pointer; font-size: $font18 !important;
  margin-right: 26px !important;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated .form-control:valid, .form-control.is-valid {
  background-position: center right calc(0.875em + 0.1875rem);

}

.nb-theme-corporate nb-checkbox.status-basic .text {
  line-height: 1.5;
}

.nb-theme-corporate nb-toggle .toggle {
  height: 1.15rem;
  width: 2.5rem; }

.nb-theme-corporate nb-toggle.status-basic .toggle {background-color: #cdd5dd; border-color: #cdd5dd;}

.nb-theme-corporate nb-toggle .toggle-switcher {
  width: 1rem;
  height: 1rem;
}

[dir=ltr] .nb-theme-corporate nb-toggle .toggle.checked .toggle-switcher {
  left: calc(100% - 1rem - 1px - 1px);
}

.nb-theme-corporate nb-toggle .toggle-switcher nb-icon {
  height: 0.55rem;
  width: 0.55rem;
}

label.required::after {
  content: '*';
  color: red;
}

.nb-theme-corporate nb-select .select-button {
  min-width: 3rem;
}

.nb-theme-corporate nb-menu .menu-item .menu-icon {margin:0; width:2em;}

.delbtn {line-height: 48px; padding: .5rem;}

.resp-data {overflow-x: hidden; width: 100%; padding-bottom: 16px;
  .cont {width:100%;}
}

label.toggle-label {margin-bottom:0;}

.nb-theme-corporate nb-tabset {
  background-color: #fff;
  border-radius: 10px; border:1px solid #eee;
  box-shadow: none;
}

.nb-theme-corporate nb-tabset nb-tab {
  padding: 1rem 1.5rem;
}

.nb-theme-corporate nb-tabset .tabset {
  border-bottom: 1px solid #eee;
}
.nb-theme-corporate .tabset .tab {
  padding: 0 0.5rem !important;
}

.nb-theme-corporate .tabset .tab a::before {
  bottom: 1px !important;
}
.nb-theme-corporate nb-tabset .tab:hover .tab-link {
  color:var(--basecolor);
}
.nb-theme-corporate nb-tabset .tab:hover .tab-link::before {
  background-color:var(--basecolor);
}
.nb-theme-corporate nb-tabset .tab.active .tab-link::before {
  background-color:var(--basecolor);
}
.nb-theme-corporate nb-tabset .tab.active .tab-link {
  background-color: transparent;
  color:var(--basecolor);
}

.nb-theme-corporate nb-checkbox.status-primary .custom-checkbox.checked {
  background-color: var(--basecolor);
  border-color: var(--basecolor);
}

.nb-theme-corporate nb-checkbox.status-primary .custom-checkbox, .nb-theme-corporate nb-checkbox.status-primary .custom-checkbox, .nb-theme-corporate nb-checkbox.status-primary .native-input:enabled:focus + .custom-checkbox {
  border-color: var(--basecolor);
}

.nb-theme-corporate nb-checkbox:hover.status-basic .custom-checkbox {border-color: var(--basecolor);}

.nb-theme-corporate nb-checkbox.status-primary .native-input:enabled:focus + .custom-checkbox.indeterminate, .nb-theme-corporate nb-checkbox.status-primary .native-input:enabled:focus + .custom-checkbox.checked {
  background-color: var(--basecolor);
  border-color: var(--basecolor);
}

.nb-theme-corporate nb-tabset nb-tab nb-card-header {padding-left:0 !important;}

.nb-theme-corporate nb-popover {
  border: 1px solid #e4e9f2;
  border-radius: 20px;
  background: #ffffff;
  box-shadow: 0 8px 7px 0 rgba(0,0,0,0.2) !important;
  color: #222b45;
  padding: 20px;
}

.leaflet-top .leaflet-control {
  margin-top: 30px !important;
}

.color-c1 {color: #FF5F5F !important;}
.color-c2 {color: #8C8CDE !important;}
.color-c3 {color: #F5BB47 !important;}
.color-c4 {color: #FF8650 !important;}
.color-c5 {color: #41C1D3 !important;}
.color-c6 {color: #21CE28 !important;}
.c-header-color1 {background: #FF5F5F;}
.c-header-color2 {background: #8C8CDE;}
.c-header-color3 {background: #F5BB47;}
.c-header-color4 {background: #FF8650;}
.c-header-color5 {background: #41C1D3;}
.c-header-color6 {background: #21CE28;}

.bg-clr1 {background:#7FD07B !important;}
.bg-clr1:hover {background:#7ee179 !important;}

.bg-clr2 {background:#FF4B4B !important;}
.bg-clr2:hover {background:#ff3131 !important;}

.bg-clr3 {background:#B87DD2 !important;}
.bg-clr3:hover {background:#ba63e0 !important;}

.bg-clr4 {background:#F2C300 !important;}
.bg-clr4:hover {background:#fdd016 !important;}

.bg-clr5 {background:#F78C21 !important;}
.bg-clr5:hover {background:#ff7611 !important;}

.bg-clr6 {background:#69B7F0 !important;}
.bg-clr6:hover {background:#50b2f9 !important;}

.bg-clr7 {background:#bcc2cd !important;}
.bg-clr7:hover {background:#b1bac9 !important;}

.bg-white {
  background-color: #fff !important;
}

.nb-theme-corporate nb-radio.status-basic:hover .native-input:enabled + .outer-circle {
  border-color: var(--basecolor) !important;
}

.nb-theme-corporate nb-radio.status-basic .native-input:enabled:checked + .outer-circle {
  border-color: var(--basecolor) !important;
}
.nb-theme-corporate nb-radio.status-basic .native-input:enabled:checked ~ .inner-circle {
  background-color: var(--basecolor) !important;
}

.nb-theme-corporate ng2-smart-table nav.ng2-smart-pagination-nav .pagination li a, .nb-theme-corporate ng2-smart-table nav.ng2-smart-pagination-nav .pagination li > span {
  color: #333;
  padding: 0.6rem 1rem;
}

.nb-theme-corporate ng2-smart-table nav.ng2-smart-pagination-nav .pagination li a:hover {
  color: #fff;
  background-color: var(--basecolor);
}

.nb-theme-corporate ng2-smart-table nav.ng2-smart-pagination-nav .pagination li.active a, .nb-theme-corporate ng2-smart-table nav.ng2-smart-pagination-nav .pagination li.active a:hover, .nb-theme-corporate ng2-smart-table nav.ng2-smart-pagination-nav .pagination li.active a:focus, .nb-theme-corporate ng2-smart-table nav.ng2-smart-pagination-nav .pagination li.active > span, .nb-theme-corporate ng2-smart-table nav.ng2-smart-pagination-nav .pagination li.active > span:hover, .nb-theme-corporate ng2-smart-table nav.ng2-smart-pagination-nav .pagination li.active > span:focus {
  background-color: var(--basecolor);
}

.f-btn-wd, .f-btn-wd1 {width:110px !important; margin: 0 0 0 10px;}

.mbsc-ios.mbsc-textfield-outline {
  border-color: #e9edf1 !important;
}

.bg-color-1 {background: #E5EEFC;}
.bg-color-2 {background: #E0F8F9;}
.bg-color-3 {background: #DEF5C6;}
.bg-color-4 {background: #EEE5FD;}
.bg-color-5 {background: #CBF4E3;}
.bg-color-6 {background: #FAE2E1;}

.orderdflex{
  display:flex;
  padding: 22px;
  border-radius: 0.8rem;
}

.nb-theme-corporate ng2-smart-table table tr th, .nb-theme-corporate ng2-smart-table table tr th a {font-size: $font12 !important;
  font-weight: $bold !important;}

.nb-theme-corporate nb-tabset {
  border: 0 solid #eee;
}

.nb-theme-corporate [nbButton].appearance-filled.status-primary[disabled]:hover, .nb-theme-corporate .appearance-filled.status-primary[disabled][nbButtonToggle]:hover {
  background-color: rgba(143, 155, 179, 0.24) !important;
  border-color: rgba(143, 155, 179, 0.24) !important;
  color: rgba(143, 155, 179, 0.48) !important;
}

.back-btn {position: relative; left: -5px; cursor: pointer; width: 28px;}

.nb-theme-corporate ng2-smart-table table tr.ng2-smart-titles th { background: #e1e7ec;
  font-size: 0.75rem !important;
  font-weight: 600 !important;
  padding: 14px 10px !important;
  white-space: nowrap;
}
.nb-theme-corporate ng2-smart-table table {margin-bottom: 1rem;}
.nb-theme-corporate ng2-smart-table table tr th, .nb-theme-corporate ng2-smart-table table tr td {border:1px solid #c5cbd0;}
.nb-theme-corporate ng2-smart-table table tr td {padding: 14px 10px !important;
  color: #212a32 !important;
  font-size: 0.813rem !important; vertical-align: top !important;}

.nb-theme-corporate nb-tabset .tab-link::before {
  height: 3px;
}
.nb-theme-corporate nb-tabset .tab-link {
  padding: 20px 1rem !important;
  font-size: 0.813rem;
  font-weight: 500;
  text-transform: capitalize !important;
}

div::-webkit-scrollbar {
  height: 5px;
}

div::-webkit-scrollbar {
  width: 5px;
}

nb-list::-webkit-scrollbar, ul::-webkit-scrollbar {
  width: 5px;
}

.nb-theme-corporate nb-route-tabset .route-tabset {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 69px; z-index: 9;
}

.toast-m {
  position: fixed !important;
  bottom: 20px !important;
}

.toast {
  border: 0 !important;
  border-radius: 20px !important; min-width: 280px;
}

ngb-toast .toast-header .close {
  margin-top: 3px !important; outline: 0 !important;
}

.toast-header {
  color: var(--basecolor) !important;
}

.chart-legend .legend-labels {
  background: none !important;
  text-align: center !important;
}

.nb-theme-corporate ng2-smart-table .ng2-smart-actions a.ng2-smart-action {
  font-size: 1.4rem !important;
}

.nb-theme-corporate ng2-smart-table .ng2-smart-actions a.ng2-smart-action:not(:last-child) {
  border-right: 0 solid #edf1f7 !important;
}

.nb-theme-corporate ng2-smart-table table-cell-edit-mode {
  margin: 0;
}

.nb-theme-corporate ng2-smart-table th.ng2-smart-actions-title-add a {
  font-size: 1.4rem !important;
  border-color: #fff !important;
  background-color: #fff !important;
  color: #000 !important;
}

.nb-theme-corporate nb-select.appearance-outline.size-medium .select-button {white-space: break-spaces;}

.p-datatable-wrapper {overflow-x: auto !important;}

.nb-theme-corporate nb-stepper.horizontal .header .step {width: 200px !important;
  background: #eff5f9;
  border-radius: 8px;
  padding: 15px; margin: 0; min-height: 130px;}

.btn-w-12 {width:12rem !important;}

.mbsc-windows.mbsc-textfield-box, .mbsc-windows.mbsc-textfield-outline {
  border-color: #e9edf1 !important;
}

.form-control:active {border-color: none !important;}
.form-control:focus {box-shadow: 0 0 0 0 var(--basecolor) !important;}

.nb-theme-corporate nb-spinner.status-primary .spin-circle {
  border-top-color: var(--basecolor) !important;
  border-right-color: transparent;
  border-bottom-color: var(--basecolor) !important;
  border-left-color: var(--basecolor) !important;
}



.mbsc-windows.mbsc-textfield-box.mbsc-focus, .mbsc-windows.mbsc-textfield-outline.mbsc-focus {
  box-shadow: inset 0 0 0 0 var(--basecolor) !important;}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.nb-theme-corporate nb-toast.status-primary {background: var(--basecolor) !important; border-color: var(--basecolor) !important;}

.leaflet-left {
  left: unset!important;
  right: 16px!important;
}

nb-icon.d-cursor {color:var(--basecolor) !important;}

//////////////////// Icons CSS start here ////////////////////
.table-icon {height:18px;}

//////////////////// Responsive CSS start here ////////////////////

@media (max-width:1540px) {

  .nb-theme-corporate nb-route-tabset .tab-link {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}

@media (max-width:1240px) {

  .nb-theme-corporate nb-route-tabset .tab-link {
    padding-left: 0.2rem !important;
    padding-right: 0.2rem !important;
  }

  .table {
    /*width: 1100px !important;*/
    max-width: inherit !important;
  }
}

@media (max-width:1199px) {

  .pagepad {
    padding: 20px 20px 19px 36px !important;
  }
}

@media (max-width:1110px) {

  .route-tabset .route-tab {padding:0 !important;}
  .nb-theme-corporate nb-route-tabset .route-tabset {overflow-y: hidden; overflow-x: auto; margin-bottom: 15px!important;}
  .nb-theme-corporate nb-route-tabset .tab-link {padding: 15px 1rem !important;}
  .nb-theme-corporate nb-route-tabset .route-tabset::-webkit-scrollbar {
    height: 5px;
  }
  /*.mbtn {display:block;}*/
  .route-tabset .route-tab a {
    white-space: nowrap;
  }



}


@media (max-width:1023px){

  .nb-theme-corporate h3 {font-size: 1.5rem;}

  .custom-tab .search-bx {text-align: left !important; margin-top: 15px}

  .p-datatable {
    table {min-width:1100px !important;}
  }

  .m-wid-h {width:100%;}

  .col-md-8, .col-md-6, .col-md-4 {flex: 0 0 100%; max-width: 100%;}
  .l-panel {order:2;}
  .l-panel.col-md-6 {flex: 0 0 100%; max-width: 100%;}
  .picsum-img-wrapper {height:100% !important;}
  .picsum-img-wrapper img {height:100%;}
  .carousel-indicators {bottom: 5px !important;}
  .r-panel {padding:20px 0; order:1;}
  .r-panel.col-md-6 {flex: 0 0 100%; max-width: 100%;}
  .login-form {width: 90% !important;}
  .info-container {display:none}

  .nb-theme-corporate nb-user.size-medium .user-picture {
    height: 2.2rem;
    width: 2.2rem;
  }
  .nb-theme-corporate nb-actions.size-medium nb-action {padding: 0 0 0 0;}

  .nb-theme-corporate nb-user.size-medium .initials {font-size: 0.875rem; font-weight: 600;}

}

@media (max-width:960px) {

  .f-btn-wd, .f-btn-wd1 {width:23% !important; margin: 0 1% !important;}

}

@media (max-width:767px) {

  .manageContainer .font20 {font-size: 1rem!important;}

  .control-item {
    display: inline-block !important;
  }

  .nb-theme-corporate nb-route-tabset .route-tabset {
    top: 55px;
  }

  .pageheader {font-size: $font20 !important;}

  .resp-data {overflow-x:auto;
    .cont {width:700px}
  }

  .c-logo-inner img {min-height: 56px; max-height: 60px;}

  .nb-theme-corporate nb-layout-header nav {
    height: 3.75rem;
    padding: 1rem;
  }
  .nb-theme-corporate nb-layout-header.fixed ~ .layout-container {
    padding-top: 3.75rem;
  }


  .nb-theme-corporate nb-layout-header.fixed ~ .layout-container nb-sidebar .main-container {
    height: calc(100vh - 3.75rem);
  }
  .nb-theme-corporate nb-layout .layout .layout-container nb-sidebar.fixed, .nb-theme-corporate nb-layout .layout .layout-container nb-sidebar .main-container-fixed {
    top: 3.75rem;
  }
  .nb-theme-corporate .header-container .navigation {
    font-size: inherit;
  }

  .nb-theme-corporate .notificationToggle {width:90% !important; right: 20px !important; top: 60px !important;}
  .cdk-overlay-pane {top:45px !important; right:20px !important;}
}

@media (max-width:576px) {

  .nb-theme-corporate nb-route-tabset .route-tab.responsive .tab-text {display:inherit;}

}
@media (max-width:575px) {

  .pagepad {
    padding: 18px 18px 19px 18px !important;
  }

  .chart-legend .horizontal-legend li {
    display: block !important;
  }

  .chart-legend .legend-label {
    margin: 4px !important;
  }

  .chart-legend .legend-labels {
    text-align: left !important;
  }
}

@media (max-width:573px) {

  .no-disp1 {display: none !important;}
  .mb-user1 {
    display: block;
  }


}



@media (max-width:490px) {

  .f-btn-wd, .f-btn-wd1 {width:48% !important; margin: 0 1% 1% 1% !important;}

}

// custom input file text

.custom-file-input1 {
  width: 100% !important; font-weight:400 !important; font-size:$font14 !important; padding: 0 1rem !important; line-height:42px !important; border-radius: 0.75rem !important; background-color: #e9edf1 !important; border: 1px solid #e9edf1 !important; font-family: $newfont !important;}


.custom-file-input1::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input1::before {
  content: 'Select File';
  display: inline-block;
  background: var(--basecolor);
  border: 1px solid var(--basecolor);
  border-radius: .4rem;
  padding: 8px 16px;
  outline: none; line-height: 1;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 600;
  font-size: .8rem; color: #fff;
}
.custom-file-input1:hover::before {
  border-color: var(--basecolor); background:var(--basecolor);
}
.custom-file-input1:active::before {
  background: var(--basecolor);
}
